:root {
  --amplify-primary-color: #386ba7 !important;
}

:root::-webkit-scrollbar {
  display: none;
}

html,
body {
  padding: 0;
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  //   Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  // background-color: lightgray;
}
//必須*を赤字に
span.MuiFormLabel-asterisk {
  color: #D00;
}
//AppBarの高さ
.MuiToolbar-regular {
  min-height: 43px !important;
}

//タイトル付枠線
fieldset {
  border-radius: 8px;
  border-color: #595959;
  border-width: 1px;
  border-style: solid;
}

//アコーディオンの上下幅
.MuiAccordionSummary-content.Mui-expanded {
  margin: 0 0 !important;
}
.MuiAccordionSummary-content {
  margin: 0 0 !important;
}

//検索条件の余白
.MuiAccordionSummary-root {
  padding: 0px 0px !important;
}
.MuiIconButton-edgeEnd {
  margin-right: 0px !important;
}

//Theme - MuiAppBar
.MuiAppBar-colorPrimary {
  background-color: #386ba7 !important;
}
//Theme - MuiRadio
.MuiRadio-colorPrimary.Mui-checked {
  color: #386ba7 !important;
}
//Theme - MuiButton
.MuiButton-containedPrimary {
  background-color: #386ba7 !important;
  color: white !important;
}
.MuiButton-containedPrimary.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}
.MuiButton-containedSecondary {
  background-color: #88bbf7 !important;
  color: #000 !important;
}
.MuiButton-containedSecondary.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}
.MuiButton-containedWarning {
  background-color: #EBB304 !important;
  color: #000 !important;
}
.MuiButton-containedWarning.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}

//Theme - MuiCheckbox
.MuiCheckbox-colorPrimary.Mui-checked {
  color: #386ba7 !important;
}
//Theme - MuiToggleButton
.MuiToggleButton-primary.Mui-selected {
  background-color: #1976d2 !important;
  color: white !important;
}
//Theme - MuiBadge
.MuiBadge-colorPrimary {
  background-color: #386ba7 !important;
  color: #fff !important;
}
.MuiBadge-colorSecondary {
  background-color: #EBB304 !important;
  color: #000 !important;
}
//Theme - MuiOutlinedInput
.editable .MuiOutlinedInput-root {
  background: #FFFFE0;
}
.editable .MuiOutlinedInput-input {
  background: #FFFFE0;
}
// チェックボックスの右余白をなくす
.handsontable .htCheckboxRendererInput {
  margin-right: 0px !important;
  vertical-align: top !important;
}

// 列幅短い場合に3点リーダーを表示
.htCore td {
  text-overflow: ellipsis;
}
.handsontable {
  // font-size: 15px !important;
}
.handsontable th, .handsontable td {
  white-space:nowrap !important;
  height: 16px !important;
  line-height: 15px !important;  
}
.handsontable .htSeparator {
  height: 0px !important;
}
.htDropdownMenu:not(.htGhostTable) {
  z-index: 1301 !important;
}
.htContextMenu:not(.htGhostTable) {
  z-index: 1301 !important;
}
//コメントポップアップ
.htCommentsContainer .htComments {
  z-index: 1301 !important;
}
.htCommentsContainer .htComments .htCommentTextArea{
  background: #EEE;
  // width: auto !important;
  // white-space: nowrap;
}

.handsontable span.colHeader {
  pointer-events: none;
}
.recharts-tooltip-wrapper {
  z-index: 1301 !important;
}
.htFiltersConditionsMenu:not(.htGhostTable){
  z-index: 1302 !important;
}

div[tabindex="-1"]:focus {
  outline: 0;
}
